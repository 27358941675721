import React, {ReactElement, useContext} from 'react';
import {SecurityContext} from '../../../../../../App';
import {Check} from '../../../../../../shared/model/check.model';
import {Dictionary} from '../../../../../../shared/model/dictionary.model';
import {SelectHelper} from '../../../../../../utils/select-helper';
import {YES_NO_SELECT_OPTIONS} from '../../../../../shared/form-bolean-option-values/YesNoSelectOptions';
import {ConfiguredCustomField} from '../configured-fields/ConfiguredCustomField';
import {ConfiguredFieldSelect} from '../configured-fields/ConfiguredFieldSelect';
import {FieldDisablementReason} from '../configured-fields/field-disablement-reason.model';
import {CheckValue} from './check-value.model';
import {getCustomCheckName, isCheckManual} from './check-values-utils';
import {getDisablementReason} from './edit-permissions-utils';
import { LoanApplicationContext } from '../../../LoanApplication';

interface Props {
  checks?: Check[];
  dictionaries?: Dictionary[];
  checkValues: CheckValue<unknown>[];
}

export default function CheckFields({checks, dictionaries, checkValues}: Props): ReactElement {

  const {userData} = useContext(SecurityContext);
  const {application} = useContext(LoanApplicationContext);

  return (
    <>
      {
        checks?.map(check => {

          const {required, id, dictionaryId, name, proofType, roleIds} = check;
          const customOptions = dictionaryId ? SelectHelper.mapDictionaryToOptions(dictionaries, dictionaryId) : [];
          const disablementReason = getDisablementReason(roleIds, userData, application);
          const field = {enabled: true, required, value: undefined, name: getCustomCheckName(checkValues, id)};
          const props = {field, key: id, label: name}; // label is field name set during configuration

          return isCheckManual(check)
            ? <ConfiguredCustomField {...props}
                                     type={proofType}
                                     customOptions={customOptions}
                                     disablementReason={disablementReason} />
            : <ConfiguredFieldSelect {...props}
                                     name={field.name}
                                     options={YES_NO_SELECT_OPTIONS}
                                     disablementReason={FieldDisablementReason.VALUE_CALCULATED_AUTOMATICALLY} />;
        })
      }
    </>
  );
}
